// Настройки фильтров под проект
import { FilterManagerPool } from "../../vue/filter/manager";
import { debounce } from "lodash";
import { onDomReady } from "../../components/dynamic/observer";

function filtersAfterInit() {
  bindChanges();
  bindButtons();

  realtyFilterAfterInit();
}

// Кнопка отправки
function bindButtons() {
  const filterSubmits = document.querySelectorAll('[data-filter-submit]');
  filterSubmits.forEach((filterSubmit) => {
    filterSubmit.addEventListener('click', () => {
      const manager = FilterManagerPool.getManager(filterSubmit.dataset.filterKey);
      manager.submit();
    });
  });
}

// Отправлять фильтр при любых изменениях
function bindChanges() {
  document.addEventListener('filter:values-changed', debounce((e) => {
    const { filterManager } = e.detail;
    filterManager.submit();
  }, 300));
}


const quarterDepend = (e) => {
 console.log(e)
};

function realtyFilterAfterInit() {
  document.addEventListener('filter:values-changed', debounce((e) => {
    const { filterManager, oldValues, newValues } = e.detail;
    if (filterManager.filterKey !== 'RealtyFilter') {
      return;
    }

    if (oldValues['RealtyFilter'].quarter.value !== newValues['RealtyFilter'].quarter.value) {
      // filterManager.update();
    }

  }, 300));
}
onDomReady(() => {
  if (FilterManagerPool.initialized) {
    filtersAfterInit();
  } else {
    document.addEventListener('filter:after-init', filtersAfterInit)
  }
});






